

.nav_wrapper {
    flex-grow: 1;
    text-align: center;
}

.nav_wrapper>header {
    z-index: 10;
}

.nav_logo {
    flex-grow: 1;
    max-width: 500px;
    margin-top: -44px;
}

.nav_tab {
    min-width: 0px !important;
}
.nav_tab:a {
    color: hotpink;
}

.nav_toolbar_spacer {
    flex-grow: 1;
}

.Mui-selected {
    color: #a80000 !important;
}

.drawer {
    width: 250px;
}

.drawer_item_nested {
    padding-left: 40px !important;
}

.drawer_item_nested_2 {
    padding-left: 65px !important;
}

@media screen and (max-width: 800px) {
    .nav_logo {
        max-width: 350px;
        margin-top: 0px;
    }
}
@media screen and (max-width: 450px) {
    .nav_logo {
        max-width: 250px;
    }
}