#root {
  max-width: 100vw;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  width: 0.5em;
}
 
body::-webkit-scrollbar-track {
  background-color: transparent;
}
 
body::-webkit-scrollbar-thumb {
  background-color: #e6e6e6;
  outline: 1px solid slategrey;
}

.app_width {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
}

.app_padding {
  padding: 16px;
}

.app_center {
  text-align: center;
}

.app_content {
  max-width: 1000px;
  width: calc(100% - 270px);
  margin: 0px auto;
  padding-left: 270px;
}

.app_auth_content {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center
}

.app_content_progress {
  margin-top: 120px;
}

.app_loader {
  position: static;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 20;
}

.app_linear_progress {
  width: 100%;
}

@media screen and (max-width: 800px) {
  .app_content {
    padding: 0;
    margin-top: 70px;
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .app_content {
    margin-top: 60px;
  }
}

.MuiTypography-body1 {
  color: #0000008a;
}
.MuiTypography-body2 {
  color: #0000008a;
}
.MuiTypography-subtitle1 {
  color: #0000008a;
}
.MuiTypography-subtitle2 {
  color: #0000008a;
}
.MuiTypography-caption {
  color: #0000008a;
}

.MuiExpansionPanel-root > div{
  padding: 0 !important;
}

.MuiExpansionPanelDetails-root {
  padding: 0 !important;
}